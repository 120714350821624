.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  .inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  .append {
    flex: 0 0 auto;
  }
}
