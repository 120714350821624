@import '~styles/index';

.root {
  width: 100%;
  margin-bottom: 1rem;

  &:focus-within {
    .limitMessage {
      display: block;
    }
  }
}

.list {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin-bottom: 0;
}

.limitMessage {
  position: absolute;
  display: none;
  margin-top: 0.5rem;
}

.imgBlock {
  padding: 4px;
  border-left: 1px solid $border-color;
  width: 100px;
}
