@import '~styles/index';

$dot-size: 8px;
$dot-shadow-size: 4px;

.dot {
  display: inline-block;
  width: $dot-size;
  height: $dot-size;
  margin: $dot-shadow-size;
  border-radius: 50%;
  vertical-align: top;

  &.procedures {
    background-color: $brand-primary;
    box-shadow: 0 0 0 $dot-shadow-size $brand-100,
      0 0 0 $dot-shadow-size * 2 $white;
  }

  &.checks {
    background-color: $validation-warning;
    box-shadow: 0 0 0 $dot-shadow-size $validation-warning-bg,
      0 0 0 $dot-shadow-size * 2 $white;
  }

  &.risks {
    background-color: $validation-error;
    box-shadow: 0 0 0 $dot-shadow-size $validation-error-bg,
      0 0 0 $dot-shadow-size * 2 $white;
  }
}
