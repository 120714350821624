.align-center {
  align-items: center !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-baseline {
  align-items: baseline !important;
}

.justify-initial {
  justify-content: initial !important;
}

.justify-between,
.justify-space-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
