@import '~styles/index';

$align-map: (
  flex-start: flex-start,
  flex-end: flex-end,
  center: center,
  stretch: stretch,
  baseline: baseline,
);

$justify-map: (
  flex-start: flex-start,
  flex-end: flex-end,
  center: center,
  space-around: space-around,
  space-between: space-between,
  space-evenly: space-evenly,
);

.row {
  position: relative;
  display: flex;
  margin-right: calc(#{-$grid-gutter-width} / 2);
  margin-left: calc(#{-$grid-gutter-width} / 2);

  &.inline {
    display: inline-flex;
  }

  // Direction

  &.row {
    flex-direction: row;
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }

  &.column {
    flex-direction: column;
  }

  &.column-reverse {
    flex-direction: column-reverse;
  }

  // Align items

  @each $a in map-keys($align-map) {
    &.align-#{$a} {
      align-items: map-get($align-map, $a);
    }
  }

  // Justify content

  @each $j in map-keys($justify-map) {
    &.justify-#{$j} {
      justify-content: map-get($justify-map, $j);
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.doubleGap {
    margin-right: calc(#{-$grid-gutter-width * 2} / 2);
    margin-left: calc(#{-$grid-gutter-width * 2} / 2);
  }
}
