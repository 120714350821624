@import '~styles/resets.scss';
@import '~styles/index.scss';
@import '~styles/spacings.scss';
@import './text.scss';
@import './flex.scss';
@import './height.scss';

.relative {
  position: relative;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.fullWidth {
  width: 100%;
}

.hidden {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}
