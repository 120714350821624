:root {
  --font-size-xxl: 32px;
  --font-size-xl: 24px;
  --font-size-lg: 18px;
  --font-size-md: 14px;
  --font-size-sm: 13px;
  --font-size-xs: 12px;
  --font-size-xxs: 10px;
  --font-size-smallest: 8px;

  @media screen and (max-width: 1100px) {
    :root {
      --font-size-xl: 20px;
      --font-size-md: 13px;
      --font-size-sm: 12px;
      --font-size-xs: 12px;
      --font-size-xxs: 10px;
      --font-size-smallest: 8px;
    }
  }
}

// FONTS

$line-height: 1.25;

$font-size-html-sm: 13px;
$font-size-html: 14px;
$font-size-html-lg: 18px;
$font-family: 'Helvetica Neue', sans-serif !important;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-xxl: var(--font-size-xxl);
$font-size-xl: var(--font-size-xl);
$font-size-lg: var(--font-size-lg);
$font-size-md: var(--font-size-md);
$font-size-sm: var(--font-size-sm);
$font-size-xs: var(--font-size-xs);
$font-size-xxs: var(--font-size-xxs);
$font-size-smallest: var(--font-size-smallest);
