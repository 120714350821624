@import '~styles/index';

.inputBase {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: auto;
  height: $input-height;
  border: $border;
  border-radius: $border-radius;
  background-color: $input-bg;
  transition: border-color $transition-duration;
  font-size: $font-size-md;
  line-height: 1.2;
  box-sizing: border-box;

  input,
  textarea {
    &::placeholder {
      color: $placeholder-color;
    }

    &:disabled {
      background-color: $input-disabled-bg;
    }
  }

  &:hover:not(.disabled) {
    border-color: $border-color-hover;
  }

  &:focus-within {
    border-color: $border-color-focus;
  }

  .append,
  .prepend {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: inherit;
    background-color: inherit;
    box-sizing: border-box;

    svg {
      transition: color $transition-duration, fill $transition-duration;
    }
  }

  .input {
    flex: 1 1 auto;
    width: auto;
    height: auto;
    padding: 0 1rem;
    border-radius: inherit;
  }

  &.multiline .input {
    padding-top: 1rem;
    resize: none;
  }
}

:global(.SelectMenu--open) + .inputComponent .input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.textBox {
  @include input;

  position: relative;
  display: inline-flex;
  min-height: $input-height;
  font-weight: $font-weight-medium;
  color: $black;
  background-color: $gray-100;
  align-items: center;

  @include ie {
    line-height: 48px;
  }
}

.textBoxTextarea {
  min-height: $textarea-height;
  padding: 1rem;
  align-items: flex-start;
}

.textBoxInner {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.hasIcon {
  padding-right: 2.5em;
}

.icon {
  position: absolute;
  top: 14px;
  right: 0.8em;
}

.languageBadge {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(calc(100% + 1em));
}
