@import '~styles/index';

.shortInfo {
  display: flex;
  align-items: center;
  margin-left: 1.5em;
  position: relative;
  appearance: none;
  cursor: pointer;
}

.menu {
  min-width: 280px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4em;
  right: 0.5em;
  z-index: $z-index-1;
  border-radius: $border-radius;
  background-color: $light;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  appearance: none;

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;

    .data {
      display: flex;
      flex-direction: column;
    }
  }

  .logoutButton {
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem;
    border: none;
    outline: none;
    color: $black;
    background-color: $gray-100;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    transition: background-color 200ms ease;

    @include border-bottom-radius($border-radius);

    &:hover,
    &:active,
    &:focus {
      background-color: $gray-300;
    }

    i {
      color: $validation-error;
    }

    .logoutText {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  &::before {
    position: absolute;
    top: -14px;
    right: 7.5px;
    content: '';

    @include triangle-top(25px, 15px, $light);
  }
}

.modalCnt {
  .content {
    background-color: red;
  }
}
