@import '~styles/index';

.badge {
  display: inline-block;
  padding: 0.46em 0.77em;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  border-radius: 15px;
  white-space: nowrap;
}

.default {
  color: $gray-600;
  background-color: $gray-200;
}

.primary {
  color: $white;
  background-color: $brand-primary;
}
