@import '~styles/index';

.customList {
  flex-direction: row;
}

.headerWithImage {
  border-right: 1px solid $gray-300;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.panelHeader {
  display: inline-flex;
}

.editableInput {
  width: 300px;
}

.popover {
  white-space: nowrap;
}

.delay:hover {
  .popover {
    visibility: visible;
    opacity: 1;
  }
}

.imgBlock {
  border-left: 1px solid $border-color;
  width: 100px;
  padding: 4px;
}
