@import '~styles/index';

.imgContainer {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
}

.imgList {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 0 5px 5px 0;

  .imgContainer {
    transition: transform 0.1s;
  }

  .imgContainer:last-child:not(:first-child) {
    transform: translateX(-6px) translateY(6px);
  }

  &:hover {
    .imgContainer:last-child:not(:first-child) {
      transform: translateY(80px);
    }
  }
}

.imgPlaceholder {
  border-radius: 0 5px 5px 0;
}

.imgPlaceholderContainer,
.imgUpload {
  width: 100%;
  height: 100%;
}

.img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 3px 0 3px $gray-300;
  object-fit: cover;
}

.imgRemoveBtn {
  display: flex;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  font-size: 8px;
  outline: none;
  border: 0;
  border-radius: 5px;
  color: $gray-600;
  background: $brand-150;
  align-items: center;
  justify-content: center;
  appearance: none;
  cursor: pointer;

  &:active {
    background-color: $brand-200;
  }
}
