@import '~styles/index';

.container {
  display: inline-block;
  padding: 0.2em;
  font-weight: normal;
  border-radius: $border-radius;
  background-color: white;
  transition: opacity 0.3s linear;
}

.length {
  color: $brand-300;

  .closeToLimit & {
    color: $validation-warning;
  }

  .overLimit & {
    font-weight: bold;
    color: $validation-error;

    &::after {
      content: '!';
    }
  }
}

.limit {
  color: black;
}
