$paper-sheet-width: 996px;
$paper-sheet-height: 1309px;

.sheet {
  display: flex;
  overflow: auto;
  width: $paper-sheet-width;
  min-height: $paper-sheet-height;
  margin: 3rem;
  padding: 4.5rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.2);
}
