@import '../../../styles/index.scss';

.error {
  color: $red-300;
}

.inputBase {
  display: flex;
  flex: 1 1 0;
  height: $input-height;
  border: $border;
  border-radius: $border-radius;
  background-color: $input-bg;
  transition: border-color $transition-duration;
  font-size: $font-size-md;
  line-height: 1.2;
  box-sizing: border-box;

  &.inputError {
    border-color: $red-300;
  }

  input {
    &::placeholder {
      color: $placeholder-color;
    }

    &:disabled {
      background-color: $input-disabled-bg;
    }

    &.sm {
      width: 3em;
    }
  }

  &:hover:not(.disabled) {
    border-color: $border-color-hover;
  }

  &:focus-within {
    border-color: $border-color-focus;
  }

  .input {
    width: 100%;
    padding: 0 1rem;
    border-radius: inherit;
  }
}
