@import '~styles/index';

.container {
  position: relative;
}

.addFileButton {
  min-width: 100px;
}

.attachmentsList {
  display: flex;
  width: 35%;
  max-width: 43em;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
}

.listRead {
  width: 700px;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration, visibility $transition-duration;

  &.dropzoneActive {
    visibility: visible;
    opacity: 1;
  }
}
