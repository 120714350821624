@import '~styles/index';

.container {
  position: relative;
  display: block;
  z-index: 1;
}

.text {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fullTextPopup {
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  width: calc(100% + 0.4em);
  background-color: #fff;
  box-shadow: 0 0 3px $gray-200;
  border-radius: $border-radius;
  padding: 0.2em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;

  .container:hover & {
    opacity: 1;
    visibility: visible;
  }
}

.hidden {
  visibility: hidden !important;
}
