@import '~styles/index';

.dropzone {
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration, visibility $transition-duration;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
