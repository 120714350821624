@import '~styles/index';

.header {
  height: $header-height;
  border-radius: 8px;
  margin-bottom: $main-padding;
}

.menuColumn {
  display: flex;
  align-items: center;
}
