@import '~styles/index';

$panel-actions-height: 5.7rem;

.panelHeader {
  margin: (-$panel-padding-y) (-$panel-padding-x) 0;
  padding: ($panel-padding-y) $panel-padding-x 0;

  &.tabs {
    padding: 0;
  }

  .close {
    position: absolute;
    top: 1.7rem;
    right: $panel-padding-x;
  }
}

.panel {
  position: relative;
  display: flex;
  padding: $panel-padding-y $panel-padding-x $panel-padding-y $panel-padding-x;
  border-radius: $border-radius;
  background-color: $light;
  flex-direction: column;

  &:global(.has-panel-actions) {
    padding-bottom: $panel-actions-height;
  }

  &.mode-material {
    border-radius: 0;

    .panelHeader {
      padding-top: $panel-padding-y * 0.6;
      padding-bottom: $panel-padding-y * 0.6;
      color: $light;
      background-color: $brand-500;
    }
  }
}

.panelBody {
  margin: 0 (-$panel-padding-x);
  padding: ($panel-padding-y/2) $panel-padding-x;
  flex-grow: 1;

  &:last-child {
    margin-bottom: -$panel-padding-y;
    padding-bottom: $panel-padding-y;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.panelActions {
  max-height: $panel-actions-height;
  padding: ($panel-padding-y/2) $panel-padding-x;
  border-radius: 0 0 $border-radius $border-radius;
  justify-content: flex-end;
  z-index: $z-index-panel-actions;

  &.actions-default {
    display: flex;
    margin: 0 (-$panel-padding-x) (-$panel-padding-y) (-$panel-padding-x);
    border-top: $border;
    background-color: $white;
  }

  &.actions-floating {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-flex;
  }
}

.shadow-1 {
  box-shadow: $shadow-100;
}

.shadow-2 {
  box-shadow: $shadow-200;
}

.shadow-3 {
  box-shadow: $shadow-300;
}

$triangle-width: 10px;
$triangle-height: 20px;

.popperArrow {
  @include size(2rem);

  position: absolute;

  &.placement {
    &-right,
    &-right-start,
    &-right-end {
      @include triangle-left($triangle-width, $triangle-height, $light);

      left: -$triangle-width;
    }

    &-right-start {
      top: 1.7rem;
    }

    &-right-end {
      bottom: 1.7rem;
    }
  }
}

.contentPanel {
  height: $content-panel-height;
  min-height: $panel-min-height;
}

.fullPage-fixed {
  height: $full-page-panel-height;
  min-height: $panel-min-height;
  margin-bottom: -$main-padding;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  .panelBody {
    overflow: auto;
    padding-bottom: 0 !important;
  }
}

.fullPage-planning {
  height: $full-page-panel-height;
  min-height: $panel-min-height;
  margin-bottom: -$main-padding;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  .panelBody {
    overflow: auto;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
  }
}

.fullPage-min {
  min-height: $min-full-page-panel-height;
}

.fullPage-max {
  min-height: 400px;
  max-height: calc(100vh - 4rem);

  .panelBody {
    overflow: auto;
  }
}
