@import '~styles/index';

.root {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .img {
      width: 100px;
      height: auto;
      margin-bottom: 2rem;
      opacity: 0.5;
    }
  }
}
