@import '~styles/index';

.notification {
  left: 50%;
  min-height: 53px;
  min-width: 300px;
  opacity: 0;
  position: fixed;
  top: 2rem;
  transform: translateX(-50%);
  transition: opacity $transition-duration, visibility $transition-duration;
  visibility: hidden;
  z-index: $z-index-loader-badge;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
