@import '../../../styles/index.scss';

$tab-immersed-padding-x: 1.5rem;
$tab-immersed-padding-y: 1.5rem;

.tabList {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.tab {
  text-decoration: none;
  cursor: pointer;
  margin-bottom: -1px;
  padding: $tab-immersed-padding-y $tab-immersed-padding-x;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $text-color-body;
  text-transform: uppercase;

  &:global(.Tablist__tab--active) {
    border-bottom: 2px solid currentColor;
    color: $brand-primary;
  }

  &:hover {
    text-decoration: none;
  }
}

.activeTab,
:global(.Tablist__tab--active) {
  pointer-events: none;
  color: $text-color-dark;
}

.disabled {
  pointer-events: none;
  color: $gray-600;
}
