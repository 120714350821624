@import '~styles/colors';
@import '~styles/typography';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font: inherit;

  &:focus {
    outline: none;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
  font-kerning: normal;
  font-family: $font-family;
  font-size: $font-size-md;
  line-height: $line-height;
  color: $text-color-body;
  background-color: $background-color-body;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0.7em;
}

a {
  text-decoration: none;
  color: $brand-primary;
}

small {
  font-size: $font-size-xxs;
}

mark {
  font-weight: $font-weight-bold;
  background-color: transparent;
}

hr {
  height: 0.5px;
  margin: 2rem 0;
  border: 0;
  background-color: $border-color;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  td {
    padding: 0;
    vertical-align: top;
  }
}

button {
  outline: none;
  border: none;
}

input,
textarea {
  min-width: 0;
  outline: none;
  border: none;
  color: inherit;
  font: inherit;

  // remove cross button in input type="search"
  &[type='search']::-ms-clear,
  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@media (prefers-contrast) {
  :focus-visible {
    box-shadow: $outline;
  }
}
