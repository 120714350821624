.preloader {
  display: inline-block;
  height: 10px;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: currentColor;
  font-size: 0;
  line-height: 0;
  opacity: 0.3;
}

.preloaderMain {
  width: 225px;
}

.preloaderSecondary {
  width: 100px;
}
