@import '../../../styles/index.scss';

.label {
  display: flex;
  position: relative;
  overflow: hidden;

  &.lg {
    padding: 0.9em 0.9em;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
  }

  &.md {
    padding: 0.9em 0.9em;
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
  }

  &.sm {
    padding: 0.9em 0.4em;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
  }

  &.xs {
    padding: 5px 2.5px;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
  }

  &.heading-xl {
    font-size: 2em;
    font-weight: $font-weight-bold;
    color: $gray-800;
    line-height: 1.25;
  }

  &.heading-xxl {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    color: $gray-800;
    line-height: 1.25;
  }

  &.heading-lg {
    padding: 0.4em 0;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    color: $gray-800;
    line-height: 1.25;
  }

  &.heading-md {
    padding: 0.9em 0;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    color: $gray-800;
    line-height: 1.25;
  }

  &.heading-sm {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  &.heading-xs {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    justify-content: center;
  }

  &.heading-xxs {
    display: block;
    margin: 0.75rem 0;
    color: $text-color-dark;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
  }
}
