@import '~styles/index';

.paginationDots {
  margin-right: 0.5em;
  cursor: default;
}

.paginationBtn {
  min-width: 11em;
  border: 0;
}

.tableHeaderRow {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2em;
  padding: 0 1rem;
}

.columnHeader {
  position: relative;
  width: 25%;
  padding-bottom: 1em;
}

.columnHeaderSortable {
  cursor: pointer;
}

.sortDirection {
  $width: 12px;
  $height: 8px;

  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  margin-left: 10px;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: '';
  }

  &::before {
    @include triangle-bottom($width, ($height / 2), $gray-500);

    top: ($height / 2);
  }

  &::after {
    @include triangle-top($width, ($height / 2), $gray-500);

    top: -(($height / 2) - 2);
  }

  .sort-desc & {
    &::before {
      display: none;
    }

    &::after {
      @include triangle-bottom($width, $height, $gray-600);
    }
  }

  .sort-asc & {
    &::before {
      display: none;
    }

    &::after {
      @include triangle-top($width, $height, $gray-600);
    }
  }
}

.container {
  position: relative;
}
