@import '~styles/index';

.attachmentPlaceholder {
  @include size(100%);

  display: flex;
  background-color: $gray-100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attachmentPlaceholderImage {
  @include size(40px);

  display: inline-block;
  margin-bottom: 0.35em;
  background-size: 100%;
}
