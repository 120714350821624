@import '~styles/index';

.sidebar {
  position: fixed;
  top: 0;
  z-index: $z-index-1;
  display: flex;
  width: $sidebar-width;
  min-height: 100vh;
  background-color: $light;
  flex-direction: column;
}

.logo {
  @include size($sidebar-width);
  @include padding-h(5px);

  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;

  .logoImage {
    width: 48px;
    height: 48px;
  }
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.navigation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;

  .navigationItem {
    @include margin-v(2.5vh);

    .historyBadge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      top: -0.6rem;
      right: -0.6rem;
      border-radius: 50%;
      background-color: $brand-primary;
      color: $white;
    }
  }
}

.collapseBtn {
  background-color: $gray-200;

  &:hover {
    background-color: $gray-300;
  }
}
