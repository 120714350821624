.height-0-75 {
  height: 0.75em;
}

.height-1-25 {
  height: 1.25em;
}

.height-1-5 {
  height: 1.5em;
}

.height-1-75 {
  height: 1.75em;
}

@for $i from 0 through 5 {
  .height-#{$i} {
    height: #{$i}em !important;
  }
}
