@import '~styles/index';

.cell {
  color: $text-color-dark;
  white-space: wrap;
  width: 100%;

  a {
    color: $text-color-dark;
  }
}
