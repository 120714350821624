@import '../../../styles/index.scss';

//Button

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  border: 1px solid transparent;
  border-radius: $border-radius;
  transition: background-color 0.2s, color 0.2s ease;
  cursor: pointer;

  // Size

  &.lg {
    height: $input-height;
    padding: 0.9em 0.9em;
    font-size: $font-size-md;

    .children {
      padding: 0 0.9em;
    }
  }

  &.md {
    height: $button-height;
    padding: 0.9em 0.9em;
    font-size: $font-size-sm;

    .children {
      padding: 0 0.9em;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
    }
  }

  &.sm {
    height: $button-height;
    padding: 0.9em 0.4em;
    font-size: $font-size-xs;

    .children {
      padding: 0 0.4em;
    }
  }

  &.xs {
    height: auto;
    padding: 5px 2.5px;
    font-size: $font-size-xs;

    .children {
      padding: 0 2.5px;
    }
  }

  // Variant

  &.primary {
    @include button-variant-primary;
  }

  &.secondary {
    @include button-variant-secondary;
  }

  &.danger {
    @include button-variant-danger;
  }

  &.secondary-danger {
    @include button-variant-secondary-danger;
  }

  &.text {
    @include button-variant-text;
  }

  &.text-danger {
    @include button-variant-text-danger;
  }

  // State

  &.disabled {
    cursor: not-allowed;
  }

  // Children

  .children {
    flex: 1 1 auto;
    text-align: center;
  }
}
