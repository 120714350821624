@font-face {
  font-family: 'impedia';
  src: url('fonts/impedia.ttf?4hx5ef') format('truetype'),
    url('fonts/impedia.woff?4hx5ef') format('woff'),
    url('fonts/impedia.svg?4hx5ef#nodus') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'impedia' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pin-locked:before {
  content: '\e916';
}
.pin-unlocked:before {
  content: '\e909';
}
.upload:before {
  content: '\e900';
}
.assessment-collection:before {
  content: '\e921';
}
.assessment-forms:before {
  content: '\e937';
}
.circle:before {
  content: '\e91b';
}
.copy:before {
  content: '\e93b';
}
.chart:before {
  content: '\e91e';
}
.chat:before {
  content: '\e93d';
}
.info:before {
  content: '\e939';
}
.bookmark-stroke:before {
  content: '\e90f';
}
.bookmark:before {
  content: '\e911';
}
.briefing:before {
  content: '\e912';
}
.checkmark:before {
  content: '\e914';
}
.debriefing:before {
  content: '\e918';
}
.delay:before {
  content: '\e919';
}
.history:before {
  content: '\e91c';
}
.incidents:before {
  content: '\e91d';
}
.log-out:before {
  content: '\e91f';
}
.menu-hamburger:before {
  content: '\e920';
}
.operation-steps:before {
  content: '\e922';
}
.patient:before {
  content: '\e923';
}
.place:before {
  content: '\e924';
}
.plus:before {
  content: '\e925';
}
.profile:before {
  content: '\e926';
}
.report-sent:before {
  content: '\e927';
}
.reports:before {
  content: '\e928';
}
.research:before {
  content: '\e929';
}
.risk:before {
  content: '\e92a';
}
.search:before {
  content: '\e92b';
}
.sidebar:before {
  content: '\e92c';
}
.lock:before {
  content: '\e92d';
}
.settings:before {
  content: '\e92e';
}
.shape:before {
  content: '\e92f';
}
.star:before {
  content: '\e931';
}
.star-stroke:before {
  content: '\e932';
}
.team:before {
  content: '\e933';
}
.timeout:before {
  content: '\e934';
}
.validation-error:before {
  content: '\e935';
}
.check-circle:before {
  content: '\e936';
}
.vital-information:before {
  content: '\e938';
}
.check:before {
  content: '\e90a';
}
.clock:before {
  content: '\e90d';
}
.delete:before {
  content: '\e910';
}
.close:before {
  content: '\e908';
}
.userpic:before {
  content: '\e902';
}
.dashboard:before {
  content: '\e904';
}
.impedia:before {
  content: '\e905';
}
.library:before {
  content: '\e906';
}
.edit:before {
  content: '\e907';
}
.magnifier:before {
  content: '\e90b';
}
.list:before {
  content: '\e903';
}
.refresh:before {
  content: '\e913';
}
.replace:before {
  content: '\e901';
}
.calendar:before {
  content: '\e93c';
}
.template:before {
  content: '\e93a';
}
.file-text:before {
  content: '\e930';
}
.drawer:before {
  content: '\e95c';
}
.download:before {
  content: '\e9c7';
}
.link:before {
  content: '\e9cb';
}
.attachment:before {
  content: '\e9cd';
}
.ellipsis:before {
  content: '\e90e';
}
.chevron-right:before {
  content: '\e90c';
}
.chevron-left:before {
  content: '\e915';
}
.chevron-down:before {
  content: '\e917';
}
.chevron-up:before {
  content: '\e91a';
}
