@import '~styles/index';

$corner-badge-size: 20px;

.cornerBadge {
  @include size($corner-badge-size);

  display: flex;
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;
  border-radius: $border-radius 0;
  color: $white;
  background-color: $brand-primary;
  align-items: center;
  justify-content: center;
}
