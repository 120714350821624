@import '~styles/index';

.text {
  line-height: $line-height;

  // Colors

  &.color-default {
    color: $text-color-dark;
  }

  &.color-secondary {
    color: $text-color-light;
  }

  &.color-primary {
    color: $brand-primary;
  }

  &.color-error {
    color: $validation-error;
  }

  &.color-warning {
    color: $validation-warning;
  }

  // Variants

  &.variant-heading-xl {
    font-size: $font-size-xl;
  }

  &.variant-heading-lg {
    font-size: $font-size-lg;
  }

  &.variant-heading-md {
    font-size: $font-size-md;
  }

  &.variant-heading-sm {
    font-size: $font-size-sm;
  }

  &.variant-paragraph-md {
    font-size: $font-size-md;
  }

  &.variant-paragraph-sm {
    font-size: $font-size-sm;
  }

  &.variant-caption {
    font-size: $font-size-xs;
  }

  &.variant-small {
    font-size: $font-size-xxs;
  }

  &.variant-error {
    font-size: $font-size-md;
  }

  // Weight

  &.weight-bold {
    font-weight: $font-weight-bold;
  }

  &.weight-semibold {
    font-weight: $font-weight-medium;
  }

  &.weight-regular {
    font-weight: $font-weight-regular;
  }

  // Transform

  &.transform-capitalize {
    text-transform: capitalize;
  }

  &.transform-uppercase {
    text-transform: uppercase;
  }

  &.transform-lowercase {
    text-transform: lowercase;
  }

  &.transform-unset {
    text-transform: none;
  }

  // Align

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  // Extra styles

  &.ellipsis {
    @include text-ellipsis();
  }

  &.inline {
    display: inline-block;
  }
}
