.container {
  display: flex;
  flex-grow: 1;
}

.header {
  flex: 0 0 auto;
}

.main {
  overflow: auto;
}
