.rbc-addons-dnd {
  &-resize-ns-anchor {
    display: none;
    width: 100%;
    text-align: center;
    position: absolute;

    &:first-child {
      top: 0;
    }

    &:last-child {
      bottom: 0;
    }
  }

  &-row-body {
    position: relative;
  }

  &-drag-row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &-over {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-resize-ns-anchor &-resize-ns-icon {
    display: none;
    border-top: 3px double;
    margin: 0 auto;
    width: 10px;
    cursor: ns-resize;
  }

  &-resize-ew-anchor {
    position: absolute;
    top: 4px;
    bottom: 0;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  &-resize-ew-anchor &-resize-ew-icon {
    display: none;
    border-left: 3px double;
    margin-top: auto;
    margin-bottom: auto;
    height: 10px;
    cursor: ew-resize;
  }

  .rbc-event {
    transition: opacity 150ms;
  }

  .rbc-event:hover &-resize-ns-icon,
  .rbc-event:hover &-resize-ew-icon {
    display: block;
  }

  &-dragged-event {
    opacity: 0;
  }

  &-is-dragging .rbc-event:not(&-dragged-event):not(&-drag-preview) {
    opacity: 0.5;
  }

  &-drag-preview {
    cursor: grabbing;
  }

  &-resizable {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
