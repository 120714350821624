$brand-100: #ebf0ff;
$brand-150: #e1e8fe;
$brand-200: #d4dbf0;
$brand-300: #0f4278;
$brand-400: #02356b;
$brand-500: #002157;

$light: white;
$white: white;
$gray-100: #f5f6fa;
$gray-200: #ebedef;
$gray-300: #dddfe5;
$gray-400: #c3c6c8;
$gray-500: #979797;
$gray-600: #76787b;
$gray-700: #414141;
$gray-800: #222;
$black: $gray-700;

$yellow: #fc0;
$green-300: #42c35a;
$orange-300: #ff9500;
$red-300: #f55341;
$red-100: #ffdbd7;

/* brand */
$brand-primary: $brand-300;
$brand-primary--hover: $brand-400;
$brand-primary--focus: $brand-500;

/* validation */
$validation-success: $green-300;
$validation-success-bg: #e3f6e6;
$validation-warning: $orange-300;
$validation-warning-bg: #ffefda;
$validation-error: $red-300;
$validation-error-light: $red-100;
$validation-error-bg: #ffeeed;

$info-text: #3c3f49;
$info-bg: #e7ecff;

$warning-text: #453c30;
$success-text: #453c30;
$error-text: #453c30;

$text-disabled: $gray-300;

/* color vars with opacity */
$green-translucent: rgba(66, 195, 90, 0.15);
$red-translucent: rgba(255, 87, 68, 0.15);
$validation-translucent: rgba($orange-300, 0.15);
$gray-translucent: rgba(34, 34, 34, 0.07);

$gray-shadow: rgba(0, 0, 0, 0.1);
$gray-shadow-100: hsla(222, 6%, 59%, 0.1);
$gray-shadow-200: rgba(144, 148, 157, 0.2);
$gray-shadow-300: rgba(144, 148, 157, 0.6);
$shadow-100: 0 0.5rem 1rem 0 $gray-shadow-100;
$shadow-200: 0 0.5rem 2rem 0 $gray-shadow-200;
$shadow-300: 0 0.5rem 2rem 0 $gray-shadow-300;

/* border */
$border-color: $gray-300;
$border-color-focus: $brand-primary;
$border-color-hover: #e1e8fe;

$border-width: 1px;

$border: $border-width solid $border-color;
$border-dashed: $border-width dashed $gray-500;

/* text */
$text-color-lighter: $gray-400;
$text-color-light: $gray-600;
$text-color-body: $gray-700;
$text-color-dark: $gray-800;
$text-green: $validation-success;

$placeholder-color: $text-color-lighter;

$background-color-body: $gray-200;

/* button */
$text-button-hover-background: $brand-100;
$text-button-focus-background: $brand-100;
$text-button-active-background: $brand-200;

/* button */
$text-danger-button-hover-background: transparentize($validation-error, 0.85);
$text-danger-button-focus-background: transparentize($validation-error, 0.85);
$text-danger-button-active-background: transparentize($validation-error, 0.75);

/* dropdown */
$dropdown-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

$outline: 0 0 0 2px $brand-primary;
$outline-error: 0 0 0 2px $validation-error;

/* input */
$input-bg: $white;
$input-disabled-bg: $gray-100;

/* tag */
$tag-bg: $brand-100;
$tag-disabled-bg: $gray-300;
