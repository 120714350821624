@import '~styles/index';

.authFormTemplate {
  width: 100%;
  display: flex;
  flex-direction: column;

  .heading {
    margin-bottom: 2rem;
  }
}
