@import '~styles/index';

// Button

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  border: 1px solid transparent;
  border-radius: $border-radius;
  transition: background-color 0.2s, color 0.2s ease;
  cursor: pointer;

  // Style

  &.fullWidth {
    width: 100%;
    display: flex;
  }

  &.capitalized {
    text-transform: uppercase;
    letter-spacing: 0.04rem;
  }

  // Size

  &.lg {
    height: $input-height;
    padding: 0.9em 0.9em;
    font-size: $font-size-md;

    .children {
      padding: 0 0.9em;
    }
  }

  &.md {
    height: $button-height;
    padding: 0.9em 0.9em;
    font-size: $font-size-sm;

    .children {
      padding: 0 0.9em;
    }
  }

  &.sm {
    height: $button-height;
    padding: 0.9em 0.4em;
    font-size: $font-size-xs;

    .children {
      padding: 0 0.4em;
    }
  }

  &.xs {
    height: auto;
    padding: 5px 2.5px;
    font-size: $font-size-xs;

    .children {
      padding: 0 2.5px;
    }
  }

  // Variant

  &.primary {
    @include button-variant-primary;
  }

  &.secondary {
    @include button-variant-secondary;
  }

  &.danger {
    @include button-variant-danger;
  }

  &.secondary-danger {
    @include button-variant-secondary-danger;
  }

  &.text {
    @include button-variant-text;
  }

  &.text-danger {
    @include button-variant-text-danger;
  }

  // State

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.loading {
    cursor: wait;

    .before,
    .after,
    .children {
      opacity: 0;
    }
  }

  // Children

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner {
      @include size(1.5em);

      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 3px;
    }
  }

  .before,
  .after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
  }

  .children {
    flex: 1 1 auto;
    text-align: center;
  }
}

// IconButton

.iconButton {
  @include button;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  &.sm {
    padding: 0.25rem;
    font-size: $font-size-xxs;
  }

  &.md {
    padding: 0.25rem;
  }

  &.primary {
    color: $brand-primary;
  }
}

// FabButton

.fabButton {
  @include button;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $fab-size;
  height: $fab-size;
  padding: 0;
  border: 0;
  font-size: $font-size-sm;
  border-radius: 50%;

  &.active {
    color: $brand-primary;
    background-color: $brand-150;
  }
}

// ButtonWithBadge

.buttonWithBadge {
  position: relative;
}

.badge {
  @include size(10px);

  position: absolute;
  top: -0.3em;
  right: -0.3em;
  display: block;
  border-radius: 50%;
  background-color: $validation-error;
}

// PageButton

.pageButton {
  min-width: 2em;
  height: 2em;
  margin-right: 0.5em;
  font-weight: $font-weight-bold;
  outline: 0;
  border: 0;
  border-radius: $border-radius;
  color: $black;
  background-color: $light;
  cursor: pointer;

  &.active {
    color: $brand-primary;
    background-color: $brand-150;
  }

  &:last-child {
    margin-right: 0;
  }
}
