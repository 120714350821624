@import '~styles/index';

.loader {
  display: inline-flex;
  align-items: center;

  .label {
    font-weight: bold;
  }

  .icon {
    $icon-size: 25px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;

    & ~ .label {
      margin-left: 1rem;
    }
  }

  .dots {
    display: inline-flex;
    align-items: center;

    .dot {
      display: inline-block;
      flex: 0 0 auto;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: black;

      &:nth-child(1) {
        margin-right: 4px;
        animation: loading-dot 1.5s -0.8s infinite linear;
      }

      &:nth-child(2) {
        margin-right: 4px;
        animation: loading-dot 1.5s -0.4s infinite linear;
      }

      &:nth-child(3) {
        animation: loading-dot 1.5s infinite linear;
      }
    }
  }
}

.spinner {
  width: 0.8em;
  height: 0.8em;
  border: 0.15em solid $brand-150;
  border-left-color: $brand-primary;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotate 2s infinite linear;
}

// Animations

@keyframes loading-dot {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
