@import '~styles/index';

.avatarMock {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $brand-100;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.defaultAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
