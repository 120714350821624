@import '~styles/index';

.fileName {
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.progress {
  width: 100%;
  height: 10px;
  border-radius: $border-radius;
  background-color: $brand-200;
  -webkit-appearance: none !important;

  @keyframes animate-stripes {
    100% {
      background-position: -100px 0;
    }
  }

  @mixin progress-extend {
    border-radius: $border-radius;
    background-color: $brand-300;
    background-image: linear-gradient(
      135deg,
      transparent,
      transparent 33%,
      rgba(255, 255, 255, 0.3) 33%,
      rgba(255, 255, 255, 0.3) 66%,
      transparent 66%
    );
    background-size: 35px 20px, 100% 100%, 100% 100%;
    animation: animate-stripes 5s linear infinite;
  }

  &::-webkit-progress-bar {
    border-radius: $border-radius;
    background-color: $brand-200;
  }

  &::-webkit-progress-value {
    @include progress-extend;
  }

  &::-moz-progress-bar {
    @include progress-extend;
  }

  &.error {
    &::-moz-progress-bar {
      background-color: $validation-error;
    }

    &::-webkit-progress-bar {
      background-color: $validation-error;
    }

    &::-webkit-progress-value {
      background-color: $validation-error;
      background-image: none;
    }
  }
}
