@import '~styles/index';

$container-width: 16em;

.uploadImageCnt {
  width: $container-width;
}

.imageStub {
  height: 8em;
  margin: 0.75em 0;
  border: $border-dashed;
}

.selectedImage {
  @extend .imageStub;

  object-fit: contain;
  width: $container-width;
}

.buttonsCnt {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .uploadBtn,
  .deleteBtn {
    flex-basis: 45%;
  }
}
