@import '~styles/index';

.root {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.header {
  border-right: 1px solid $gray-300;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  .content {
    display: flex;
    flex-direction: column;
  }
}

.nameInput {
  min-width: 30em;
  font-weight: bold;
}

.descriptionInput {
  min-height: 6em;
  flex-grow: 1;
  align-items: stretch;
}
