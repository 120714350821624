@import '~styles/index';

.customList {
  flex-direction: row;
  margin-bottom: 0;
}

.limitMessage {
  display: none;
  font-size: $font-size-xs;
}

.container {
  margin-bottom: 1rem;

  &:focus-within {
    .limitMessage {
      display: block;
    }
  }
}

.iconCol {
  padding-right: 0;
}

.panelHeader {
  display: inline-flex;
}

.popover {
  white-space: nowrap;
}

.delay:hover {
  .popover {
    visibility: visible;
    opacity: 1;
  }
}

.imgBlock {
  padding: 4px;
  border-left: 1px solid $border-color;
  width: 100px;
}

.listWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.listHeader {
  border-right: 1px solid $gray-300;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.listItemInput {
  flex-grow: 1;
  align-items: stretch;
}

.editableInput {
  font-weight: bold;
}

.elementNameCol {
  min-width: 27em; // 50 chars
}
