@import '~styles/index';

.header {
  border: $border;
  border-bottom: 0 !important;

  .headerInner {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.5rem;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $red-300;
  }
}

.body {
  cursor: pointer;

  &.empty {
    color: $placeholder-color;
  }
}

.input {
  width: calc(100% + 2px);
  padding: 1rem;
  margin: 0 -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  &:hover,
  &:focus {
    border-color: $brand-300;
  }
}
