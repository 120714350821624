@import '~styles/sizes';

.container {
  display: flex;
  background-color: #ebedef;
}

.center {
  width: calc(100% - #{$sidebar-width});
  min-width: $main-min-width;
  margin-left: $sidebar-width;
  padding: $main-padding;
  flex: 1 0 auto;
}
