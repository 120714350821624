.barColumn {
  position: relative;
  display: flex;
  width: 177px;
  padding-left: 30px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.1s;
}

.barColumnHidden {
  opacity: 0;
}
