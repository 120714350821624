@import '~styles/index';

.count {
  display: inline-block;
  height: 32px;
  padding: 0.5em 1em;
  border: $border;
  border-radius: $border-radius;
}

.input {
  width: 50px;
  height: 32px;
  padding: 0.5 1em;
}
