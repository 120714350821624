@import '~styles/index';

.modal {
  position: relative;
  width: 100%;
  margin: auto;
  outline: none;
  animation: appear 0.2s ease-out;

  &.md {
    max-width: 640px;
  }

  &.lg {
    max-width: 1024px;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  overflow: auto;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s;
  animation: fade-in 0.2s ease-out;
  display: flex;
  // justify-content: center;
  // align-items: center;
}

.modalHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1em;

  .title {
    margin-bottom: 0;
  }

  .closeButton {
    margin-left: auto;
    padding: 0;
    font-size: $font-size-xxs;
    border: none;
    background: none;
    appearance: none;
    cursor: pointer;
  }
}
