@import '~styles/index';

$spacing-map: (
  '05': 0.5rem,
  '0': 0,
  '1': 1rem,
  '1-5': 1.5rem,
  '2': 2rem,
  '2-5': 2.5rem,
  '3': 3rem,
  '3-5': 3.5rem,
  '4': 4rem,
  '4-5': 4.5rem,
  '5': 5rem,
  '5-5': 5.5rem,
);

@each $key in map-keys($spacing-map) {
  $spacing: map-get($spacing-map, $key);

  // MARGIN

  .m-#{$key} {
    margin: $spacing !important;
  }

  .mt-#{$key} {
    margin-top: $spacing !important;
  }

  .mr-#{$key} {
    margin-right: $spacing !important;
  }

  .mb-#{$key} {
    margin-bottom: $spacing !important;
  }

  .ml-#{$key} {
    margin-left: $spacing !important;
  }

  // PADDING

  .p-#{$key} {
    padding: $spacing !important;
  }

  .pt-#{$key} {
    padding-top: $spacing !important;
  }

  .pr-#{$key} {
    padding-right: $spacing !important;
  }

  .pb-#{$key} {
    padding-bottom: $spacing !important;
  }

  .pl-#{$key} {
    padding-left: $spacing !important;
  }
}
