@import '~styles/index';

.default {
  display: flex;
  margin-bottom: 1.3em;
  padding: 1em 1.375em;
  border-radius: 5px;
  background-color: $gray-200;
  align-content: center;
  align-items: center;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;

  &.inline {
    display: inline-flex;
  }
}

.error {
  color: $error-text;
  background-color: $validation-error-bg;

  .icon {
    color: $red-300;
  }
}

.success {
  color: $success-text;
  background-color: $validation-success-bg;

  .icon {
    color: $green-300;
  }
}

.warning {
  color: $warning-text;
  background-color: $validation-warning-bg;

  .icon {
    color: $validation-warning;
  }
}

.info {
  color: $info-text;
  background-color: $info-bg;

  .icon {
    color: $brand-primary;
  }
}
