.indicator {
  position: absolute;
  right: 0;
  bottom: -1.5em;
  z-index: 1;
  visibility: hidden;
}

.container {
  position: relative;

  &:focus-within .indicator {
    visibility: visible;
  }
}
