@import '~styles/index';

.LOW {
  color: $yellow;
}

.MED {
  color: $orange-300;
}

.HIGH {
  color: $red-300;
}
