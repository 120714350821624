@import '~styles/index';

.dropdownContent {
  position: absolute;
  left: 100%;
  top: 0;
  width: 18rem;
  max-height: 400px;
  padding: 0 0.5rem;

  .dropdownInner {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: $dropdown-shadow;

    .dropdownItem {
      display: inline-flex;
      position: relative;
      cursor: pointer;
      padding: 0.9rem;
      z-index: $z-index-select-menu;
      background-color: $white;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      color: $text-color-body;
      min-height: 40px;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &:hover,
      &:focus {
        background-color: $gray-200;
        color: $brand-primary--hover;
      }
    }
  }
}
