@import '../../../styles/index.scss';

.toolbar {
  display: flex;
  gap: 0.5em;
}

.textAreaWrapper {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: $border-radius;
  border: $border;

  .editorWrapper {
    position: relative;
    padding: 0.5em 0;

    .contentEditable {
      min-height: $input-height;
      padding: 1em;
      border-radius: $border-radius;
      border: $border;
      background-color: $input-bg;
      transition: border-color $transition-duration;
      font-size: $font-size-md;
      line-height: 1.2;

      &.sm {
        min-height: 1em;
      }

      &.md {
        min-height: 6em;
      }

      &:focus-within {
        border-color: $border-color-focus;
      }

      &:hover:not([contenteditable='false']) {
        border-color: $border-color-hover;
      }

      &[contenteditable='false'] {
        border: none;
        padding: 1em 2em;
      }
    }

    .placeholder {
      position: absolute;
      top: 0.5em;
      padding: 1em;
      color: $placeholder-color;
      pointer-events: none;
    }
  }
}

.editorBold {
  font-weight: bold;
}

.editorParagraph {
  margin-block-start: 0;
  margin-block-end: 0;
}
