@import '~styles/index';

@mixin formElement {
  min-width: $form-body-min-width;
  max-width: $form-body-max-width;
}

.body {
  @include formElement();

  &:last-child {
    padding-bottom: 10rem;
  }

  &.narrow {
    width: 70%;
  }

  &::after {
    content: ' ';
  }
}

.actions {
  @include formElement();

  padding-top: 2rem;
  align-items: center;
}
