@import '~styles/index';

$attachment-picture-size: 80px;

.container {
  @include size($attachment-picture-size);

  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
}

.file,
.image {
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  pointer-events: none;
}

.image {
  object-fit: cover;
  background-color: $gray-300;
}

.file {
  display: flex;
  border: $border;
  border-radius: $border-radius;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileIcon {
  width: 30px;
  height: 50px;
}

.extension {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $brand-primary;
}
