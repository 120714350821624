@import '~styles/index';

.col {
  position: relative;
  display: block;
  max-width: 100%;
  min-width: 0;
  padding-right: calc(#{$grid-gutter-width} / 2);
  padding-left: calc(#{$grid-gutter-width} / 2);
  flex-basis: 0;
  flex-grow: 0;

  @for $i from 1 through 12 {
    &.width-#{$i} {
      $width: 100% / 12 * $i;

      max-width: $width;
      flex: $width;
    }
  }
}

.width-auto {
  width: auto;
  max-width: none;
  flex: 0 0 auto;
}

.grow {
  flex-grow: 1;
}
