@import '~styles/index';

.uploadImageCnt {
  display: flex;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  text-align: center;
  flex-direction: column;
  align-items: center;

  input[type='file'] {
    display: none;
  }
}

.userName {
  margin-top: 1.3em;
  margin-bottom: 0.2em;
  color: $gray-700;
}

.userEmail {
  margin-bottom: 1.5em;
}

.imageStub {
  display: flex;
  width: 2.25em;
  height: 2.25em;
  font-size: 3.8rem; /* stylelint-disable-line */
  font-weight: $font-weight-bold;
  border-radius: 50%;
  background-color: $border-color-hover;
  align-items: center;
  justify-content: center;
}

.selectedImage {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  object-fit: cover;
}
