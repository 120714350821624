@import '~styles/index';

.text {
  // Appearance
  background-color: $brand-primary;
  border-radius: $border-radius-sm;
  color: $white;
  padding: 0.2em 0.4em;

  // Positioning
  left: 50%;
  position: absolute;
  top: calc(100% + 0.2em);
  transform: translateX(-50%);
  z-index: $z-index-popover;

  // Text handling
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Visibility
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  visibility: hidden;
}

.popover {
  position: relative;
  display: inline-block;

  &:hover {
    .text {
      visibility: visible;
      opacity: 1;
    }
  }
}
