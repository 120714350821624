@import '~styles/index';

.list {
  @include border;

  display: flex;
  margin-bottom: 1rem;
  background-color: $white;
  flex-direction: column;

  &.shadow {
    box-shadow: $shadow-200;
  }
}

.inlineChildren {
  margin: -1px;
  flex-grow: 1;

  .input {
    height: 52px;
    background: transparent;

    &:focus {
      box-shadow: inset 0 0 8px 4px rgba(225, 232, 254, 0.2);
    }
  }
}

.itemBase {
  display: flex;
  margin: 0 -1px;
  padding: $list-item-padding;
  border: 1px solid transparent;
  align-items: center;
  justify-content: space-between;

  &.dragging {
    border-bottom: 0 !important;
    background-color: $gray-100;
  }

  &:first-child {
    &,
    & .input {
      border-radius: $list-item-border-radius $list-item-border-radius 0 0;
    }
  }

  &:last-child {
    margin-bottom: -1px;

    &,
    & .input {
      border-radius: 0 0 $list-item-border-radius $list-item-border-radius;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  &:first-child:last-child {
    &,
    & .input {
      border-radius: $list-item-border-radius;
    }
  }

  &.hoverable:hover {
    border-color: $border-color-hover;
    color: $text-color-dark;
  }

  &.clickable {
    cursor: pointer;
    margin: 0;

    &:hover,
    &:focus {
      background-color: $gray-100;
    }

    &:active {
      color: $white;
      background-color: $brand-primary--focus;
    }
  }

  &.hasInlineChildren {
    padding: 0;
  }

  &.addonOnHover {
    .addon {
      opacity: 0;
      transition: opacity $transition-duration;

      &:focus-within {
        opacity: 1;
      }
    }

    &:hover {
      .addon {
        opacity: 1;
      }
    }
  }
}

.itemDisabled {
  background-color: $gray-200;
}

.header {
  margin: -1px;
  border: 1px solid $border-color;
  color: $text-color-dark;
  background-color: $gray-100;
}

.inputWrapper {
  display: block;

  > input,
  > textarea {
    display: block;
  }
}

.contentGrow {
  flex-grow: 1;
}
