@import '~styles/index';

.iconEmph {
  @include size(3rem);

  align-items: center;
  background-color: $gray-200;
  color: $text-color-light;
  display: inline-flex;
  font-size: 1.2em; /* stylelint-disable-line */
  justify-content: center;
  border-radius: $border-radius;
}

.warning {
  color: $validation-warning;
  background-color: $validation-warning-bg;
}

.success {
  color: $validation-success;
  background-color: $validation-success-bg;
}

.error {
  color: $validation-error;
  background-color: $validation-error-bg;
}

.info {
  color: $brand-primary;
  background-color: $info-bg;
}
