@import '~styles/index';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .inputWrapper {
    display: inline-block;
    margin: 0 0.3rem;
  }

  .input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 1.2rem;
    text-align: center;
    background-color: transparent;
    appearance: none;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    overflow: hidden;

    input,
    textarea {
      width: 100%;
      height: 100%;
      padding: 0 0.3rem;
      text-align: center;
    }
  }
}
