@import '~styles/index';

.participant {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 2rem;
  margin-bottom: 1rem;

  .user,
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
}
