@import '~styles/index';

.label {
  display: flex;
  align-items: stretch;
  border: $border;
  font-weight: $font-weight-medium;
  height: $input-height;
  min-width: $input-height;
  overflow: hidden;

  &:not(:last-child) {
    border-right: 0;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.labelText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  padding: 0 1rem;
  font-size: $font-size-md;
  line-height: 1.4;
  text-align: center;
  color: $text-color-light;
  cursor: pointer;
}

.container {
  display: inline-flex;
  border-radius: $border-radius;
  background-color: $input-bg;
}

.size-sm {
  .label {
    height: $input-height-sm;
  }
}

.input {
  appearance: none;
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;

  &:disabled ~ .labelText {
    cursor: default;
    background-color: $gray-100;
  }

  &:checked ~ .labelText {
    color: $brand-primary;
    background-color: $brand-100;
  }
}
