@import '~styles/index';

.message {
  position: fixed;
  top: -18px;
  right: 0;
  left: 0;
  display: flex;
  min-width: 18em;
  justify-content: center;
  align-self: center;
}

.messageBlock {
  z-index: $z-index-2;
  white-space: nowrap;
  justify-content: center;
}
