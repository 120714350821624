/* breakpoints */
$breakpoint-phone: 400px;
$breakpoint-phone-wide: 480px;
$breakpoint-tablet-small: 640px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-wide: 1024px;
$breakpoint-desktop: 1248px;
$breakpoint-desktop-wide: 1440px;

$header-height: 3.7rem;

$settings-panel-padding: 1.875em;

$main-margin: 5.25rem; /* includes size of sidebar */
$main-padding: 2rem;
$main-min-height: 540px;

$base-template-padding-x: 2rem;
$base-template-padding-y: 2rem;

$content-panel-height: calc(
    100vh - (0.1rem + #{$header-height} + #{$main-padding} * 2)
);
$full-page-panel-height: calc(
    100vh - (0.1rem + #{$header-height} + #{$main-padding} * 2)
);
$min-full-page-panel-height: calc(
    100vh - #{$header-height} - #{$main-padding} - #{$base-template-padding-y}
);
$panel-min-height: 480px;

$grid-gutter-width: 1rem;

$container-width-xxs: 480px;
$container-width-xs: 595px;
$container-width-sm: 620px;

$form-body-min-width: $container-width-xxs;
$form-body-max-width: $container-width-xs;
$modal-body-width: $container-width-sm;

/* borders */
$border-radius-sm: 0.4em;
$border-radius: 0.5em;

$list-item-border-radius: $border-radius;

$transition-duration: 0.2s;

$button-height: 40px;
$button-height-lg: 48px;
$input-height: 48px;
$input-height-sm: 32px;
$input-padding: 0 1rem;

$select-option-padding-y: 0.5rem;
$select-option-padding-x: 1rem;
$search-option-padding-x: 2rem;

$select-menu-height: 170px;

$fab-size: 36px;

$sidebar-width: 64px;
$main-min-width: 890px;

/* Auth */
$auth-form-width: 70%;

/* Settings/Profile */
$upload-image-width: 12.875em;

/* List */
$list-item-padding: 1rem;

/* Menu */
$menu-width: 17em;

/* Modal */
$modal-window-width: 640px;

$textarea-height: 6.92rem;

$signature-pad-width: 23.07rem;
$signature-pad-height: 11.46rem;
