@import '~styles/index';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .options {
    &:not(:empty) {
      margin-bottom: -0.5rem;
    }

    .option {
      flex: 0 0 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.3rem 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      border: $border;
      border-radius: $border-radius-sm;
      color: $gray-400;
      cursor: default;

      &:last-child {
        margin-right: 0;
      }

      &.highlight {
        border-color: transparent;
        color: $green-300;
        background-color: $green-translucent;
      }
    }
  }
}
