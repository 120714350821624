@import '~styles/index';

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1em;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
}
