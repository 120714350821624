@import '~styles/index';

$container-padding: 0.25rem;

.removeButton {
  position: absolute;
  top: $container-padding;
  right: $container-padding;
  z-index: $z-index-0;
  background-color: $brand-200 !important;
  opacity: 0;
  transition: opacity 0.3s;
}

.container {
  position: relative;
  padding: $container-padding;
  border-radius: $border-radius;

  &:hover {
    .removeButton {
      opacity: 1;
    }
  }
}

.selected {
  box-shadow: 0 0 0 2px $brand-primary;
}

.selectedLabel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.activeAttachment {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
}

.viewButton {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: $z-index-0;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity $transition-duration linear,
    visibility $transition-duration linear;
  transition-delay: 0.2s;

  .container:hover & {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
  }
}
