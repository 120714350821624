.text-primary {
  color: $brand-primary;
}

.text-bold {
  font-weight: $font-weight-bold;
}

.text-strong {
  font-weight: $font-weight-bold;
  color: $text-color-dark;
}

.text-semibold {
  font-weight: $font-weight-medium;
}

.text-semistrong {
  font-weight: $font-weight-medium;
  color: $text-color-dark;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  display: inline-block;
  text-transform: capitalize;
}

.text-light {
  color: $text-color-light;
}

.text-lighter {
  color: $text-color-lighter;
}

.text-body,
.text-default {
  color: $text-color-body;
}

.text-dark {
  color: $text-color-dark;
}

.text-placeholder {
  color: $placeholder-color;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: $validation-success;
}

.text-error,
.text-danger {
  color: $validation-error;
}

.text-warning {
  color: $validation-warning;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-md {
  font-size: $font-size-md;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-xxs {
  font-size: $font-size-xxs;
}

.white-space-normal {
  white-space: normal !important;
}

.nowrap,
.white-space-nowrap {
  white-space: nowrap;
}

.text-block-inline {
  display: inline-block;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.link-text {
  color: inherit;
}
