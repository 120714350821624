@import '~styles/index';

.root {
  position: relative;
  display: inline-block;
}

.list {
  z-index: $z-index-select-menu;
  min-width: 130px;

  &[placement='bottom'] {
    margin-top: 0.5rem;
  }
}
