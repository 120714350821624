@import '~styles/index';

.categoryBadge {
  display: inline-flex;
  align-items: center;
  font-size: $font-size-xs;
  text-transform: capitalize;

  .icon {
    margin-right: 0.5rem;
  }

  &.risk {
    color: $validation-error;
  }

  &.warning {
    color: $validation-warning;
  }
}
