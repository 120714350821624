@import '~styles/index';

.root {
  display: flex;
  flex-direction: column;
  width: $auth-form-width;

  .form {
    width: 100%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .submit,
    .back {
      width: 40%;
    }
  }
}
