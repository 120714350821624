@import '~styles/index';

.indicator {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
  visibility: hidden;
}

.input {
  position: relative;
  min-height: 100% !important;
  max-height: none !important;
  margin: 0;
  padding: $list-item-padding;
  padding-right: 5em;
  outline: none !important;
  border-radius: 0;
  appearance: none;
}

.description {
  position: relative;
  flex: 1 0 0;
  min-height: 100% !important;
  max-height: none !important;
  margin: 0;
  padding: $list-item-padding;
  padding-right: 5em;
  cursor: text;
}

.descriptionPlaceholder {
  color: $placeholder-color;
}

.listItem {
  position: relative;
  border: 0;
  justify-content: stretch;

  &:last-child {
    &,
    .input {
      border-bottom-left-radius: $border-radius;
    }
  }

  &:focus-within {
    .indicator {
      visibility: visible;
    }
  }
}

.textareaWrapper {
  flex: 1 0 0;
  height: auto;
}
