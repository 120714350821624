@import '~styles/index';

.container {
  border-radius: $border-radius;
  height: $button-height;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: #fff;
}
