@import '~styles/index';

@mixin toggle($togglrColor, $btnColor) {
  background: $togglrColor;

  .toggleButton {
    background: $btnColor;
  }
}

.toggleContainer {
  width: 100%;
}

.box {
  user-select: none;
  cursor: pointer;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border: $border;
  border-radius: 10px;
  transition: background-color $transition-duration;
}

.toggleButton {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform $transition-duration;
}

.disabled {
  .toggle {
    opacity: 0.5;
  }
}

.checkboxInput {
  appearance: none;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  & ~ .toggle {
    @include toggle($gray-200, $gray-500);
  }

  &:checked {
    ~ .toggle .toggleButton {
      transform: translateX(14px);
    }

    & ~ .toggle {
      @include toggle($gray-200, $brand-300);
    }
  }
}
