@import '~styles/index';

.toolbar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;

  .today {
    margin-right: 3rem;
  }

  .prev,
  .next {
    margin-right: 1rem;
  }

  .datePicker {
    width: 280px;
  }
}
