.flex {
  display: flex;
  flex-wrap: wrap;
}

.inline {
  display: inline-flex;
  align-items: baseline;
}

.fullWidth {
  width: 100%;
}

.nowrap {
  flex-wrap: nowrap;
}
