@keyframes appear {
  from {
    transform: translateY(-0.5em);
  }

  to {
    transform: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
