@import '~styles/index';

.padWrapper {
  width: $signature-pad-width * 2;
}

.pad {
  width: $signature-pad-width * 2;
  height: $signature-pad-height * 2;
  margin-bottom: 1rem;
  border: $border-dashed;

  img {
    width: 100%;
  }
}
