@import '~styles/index';

@mixin selected($color, $backgr, $icon-color) {
  color: $color;
  background-color: $backgr;

  .iconCircle {
    color: $icon-color;
  }
}

.subMenuCnt {
  position: absolute;
  top: 0;
  left: calc(100% + 0.5rem);
  z-index: $z-index-select-menu;
  overflow-y: auto;
  width: $menu-width;
  max-height: 400px;
  border-radius: $border-radius;

  .hideCnt {
    display: none;
  }

  ul {
    padding: 0;
    list-style: none;
  }
}

.test {
  position: absolute;
}

.iconCircle {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  text-align: center;
  border-radius: 50%;

  &.count {
    width: 2.5em;
    height: 2.5em;
    padding-top: 8px;
    color: $gray-600;
    background-color: $gray-translucent;
  }
}

.listElement {
  position: relative;
  display: flex;
  margin-bottom: 0.5em;
  padding: 0.9em 0.9em;
  font-weight: $font-weight-bold;
  border: 1px solid $gray-200;
  border-radius: $border-radius;
  color: $black;
  align-items: center;
  justify-content: center;
  font-size: $font-size-sm;
  min-height: 40px;
  cursor: pointer;

  .elementLabel {
    flex: 1 1 auto;
    padding: 0 0.9em;
  }

  a {
    font-weight: $font-weight-bold;
    color: $black;
  }

  &.selected {
    @include selected($brand-primary, $gray-300, $brand-primary);

    &.secondary {
      background-color: $gray-300 !important;
    }
  }

  &.primary {
    color: $light;
    background-color: $brand-primary;
    border: 0;

    a,
    .iconCircle {
      color: $light;
    }

    &.selected {
      @include selected($light, $brand-primary, $light);

      &:hover {
        background-color: $brand-primary;
      }
    }

    &:hover {
      background-color: $brand-primary--hover;
    }
  }

  @for $i from 0 through 9 {
    &.secondary:nth-child(#{$i}) {
      background-color: rgba(
        221 + $i * 5,
        223 + $i * 5,
        229 + $i * 5,
        1 - $i / 10
      );
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:not(.selected):not(.primary) {
    &:hover,
    :focus,
    :active {
      @include selected($brand-primary, $gray-300, $brand-primary);
    }
  }
}
