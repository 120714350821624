$z-index-0: 1;
$z-index-1: 1000;
$z-index-2: 2000;
$z-index-3: 3000;
$z-index-4: 4000;
$z-index-5: 5000;
$z-index-6: 6000;
$z-index-7: 7000;
$z-index-8: 8000;
$z-index-9: 9000;

$z-index-panel-actions: $z-index-2;
$z-index-select-menu: $z-index-3;
$z-index-popover: $z-index-4;
$z-index-modal-overlay: $z-index-5;
$z-index-modal: $z-index-6;
$z-index-loader-badge: $z-index-7;
