@mixin remove-scrollbar() {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin border-right-radius($top, $bottom: $top) {
    border-top-right-radius: $top;
    border-bottom-right-radius: $bottom;
}

@mixin border-left-radius($top, $bottom: $top) {
    border-top-left-radius: $top;
    border-bottom-left-radius: $bottom;
}

@mixin border-top-radius($left, $right: $left) {
    border-top-left-radius: $left;
    border-top-right-radius: $right;
}

@mixin border-bottom-radius($left, $right: $left) {
    border-bottom-left-radius: $left;
    border-bottom-right-radius: $right;
}

// make the text exceeding the width of the container to end with three dots
@mixin text-ellipsis() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin border() {
    border: $border;
    border-radius: $border-radius;
}

@mixin screen-min-max($min-width, $max-width) {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}

@mixin input {
    width: 100%;
    padding: $input-padding;
    font-size: $font-size-md;
    line-height: 1.2;
    outline: 0 !important;
    border: $border;
    border-radius: $border-radius;
    background-color: $input-bg;
    transition: border-color $transition-duration;

    &:hover:not(.disabled) {
        border-color: $border-color-hover;
    }

    &:focus {
        border-color: $border-color-focus;
        // disable standard focus highlight in favor of custom input styling
        box-shadow: none;
    }

    &::placeholder {
        color: $placeholder-color;
    }
}

@mixin input-as-button {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    input[type='file'] {
        display: none;
    }

    display: inline-flex;
    padding: 12px;
    border-radius: $border-radius;
    color: $white;
    background-color: $brand-primary;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $brand-400;
    }

    &:active {
        background-color: $brand-500;
    }
}

@mixin settings-loader {
    position: absolute;
    align-self: center;
    top: 6em;
}

@mixin ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// Button component mixins
// All button variants encapsulated into separate mixin to use them in old components which using @mixin button and new button component

@mixin button-disabled {
    &:disabled,
    &.disabled,
    &:global(.disabled),
    &:global(:disabled) {
        @content;
    }
}

@mixin button-working {
    &:not(.loading):not(.disabled):not(:disabled) {
        @content;
    }
}

@mixin button-variant-primary {
    color: $white;
    background-color: $brand-primary;

    @include button-working {
        &:hover {
            background-color: $brand-400;
        }

        &:focus,
        &:active {
            background-color: $brand-500;
        }
    }

    @include button-disabled {
        color: $gray-200;
        background-color: $gray-300;
        border-color: $border-color;
    }
}

@mixin button-variant-secondary {
    color: $brand-primary;
    background-color: $white;
    border-color: $border-color;

    @include button-working {
        &:hover {
            background-color: $brand-150;
        }

        &:focus,
        &:active {
            background-color: $brand-200;
        }
    }

    @include button-disabled {
        color: $gray-300;
        background-color: $white;
        border-color: $border-color;
    }
}

@mixin button-variant-danger {
    color: $white;
    background-color: $validation-error;

    @include button-working {
        &:hover {
            background-color: darken($validation-error, 10);
        }

        &:focus,
        &:active {
            background-color: darken($validation-error, 20);
        }
    }

    @include button-disabled {
        color: $gray-200;
        background-color: $gray-300;
        border-color: $border-color;
    }
}

@mixin button-variant-secondary-danger {
    color: $validation-error;
    background-color: $white;
    border-color: $border-color;

    @include button-working {
        &:hover {
            background-color: $validation-error-bg;
        }

        &:focus,
        &:active {
            background-color: $validation-error-light;
        }
    }

    @include button-disabled {
        color: $gray-300;
        background-color: $white;
        border-color: $border-color;
    }
}

@mixin button-variant-text {
    color: $brand-primary;
    background-color: transparent;

    @include button-working {
        &:hover {
            color: $brand-primary--hover;
        }

        &:focus,
        &:active {
            color: $brand-primary--focus;
        }
    }

    @include button-disabled {
        color: $gray-300;
    }
}

@mixin button-variant-text-danger {
    color: $validation-error;
    background-color: transparent;

    @include button-working {
        &:hover {
            background-color: transparentize($validation-error, 0.75);
        }

        &:focus,
        &:active {
            background-color: transparentize($validation-error, 0.85);
        }
    }

    @include button-disabled {
        color: $gray-300;
    }
}

@mixin button {
    cursor: pointer;
    appearance: none;
    text-align: center;
    transition:
        background-color 0.2s,
        color 0.2s ease;

    &.loading {
        cursor: wait;
    }

    @include button-disabled {
        cursor: not-allowed;
    }

    &:global(.disabled):not(:disabled) {
        pointer-events: none;
    }

    &.primary {
        @include button-variant-primary;
    }

    &.secondary {
        @include button-variant-secondary;
    }

    &.danger {
        @include button-variant-danger;
    }

    &.secondary-danger {
        @include button-variant-secondary-danger;
    }

    &.text {
        @include button-variant-text;
    }

    &.text-danger {
        @include button-variant-text-danger;
    }

    &.icon {
        line-height: 0;
        color: $text-color-body;
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: $brand-150;
        }

        &:active {
            background-color: $brand-200;
        }

        &:global(.disabled),
        &:disabled {
            color: $gray-300;
            background-color: transparent;
        }

        &.keepBackground {
            background-color: $brand-150;
        }
    }
}
