@import '~styles/index';

$tab-padding-x: 1rem;
$tab-padding-y: 1.5rem;

$tab-immersed-padding-x: 0.5rem;
$tab-immersed-padding-y: 1.5rem;

.tabList {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.tab {
  padding: $tab-padding-y $tab-padding-x;
}

.activeTab,
:global(.Tablist__tab--active) {
  pointer-events: none;

  .simple & {
    color: $text-color-dark;
  }
}

.tabs-immersed {
  padding-right: $panel-padding-x;
  padding-left: $panel-padding-x;
  border-bottom: $border;
  background-color: $gray-100;

  .tab {
    margin-right: 2rem;
    margin-bottom: -1px;
    padding: $tab-immersed-padding-y $tab-immersed-padding-x;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $text-color-body;

    &:global(.Tablist__tab--active) {
      border-bottom: 2px solid currentColor;
      color: $brand-primary;
    }
  }
}

.tabs-default.fill-panel-header {
  padding-top: ($tab-padding-y * 0.8);
  padding-right: ($panel-padding-x * 2 + 1.375rem);
  padding-bottom: ($tab-padding-y * 0.8);
  padding-left: $panel-padding-x;
  border-bottom: $border;
}
