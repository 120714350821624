@import '~styles/index';

.menuCol {
  opacity: 0;
  transition: opacity 0.3s;
}

.container {
  position: relative;
  max-width: 400px;
  border-radius: $border-radius;

  &:hover {
    background-color: $gray-100;

    .menuCol {
      opacity: 1;
    }
  }
}

.selected {
  box-shadow: 0 0 0 2px $brand-primary;
}

.input {
  width: 100%;
}

.selectedLabel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.name {
  word-break: break-all;
}
