@import '~styles/index';

.ManualPopover {
  position: absolute;
  top: 50%;
  left: calc(100% + 0.7rem);
  z-index: 1;
  visibility: hidden;
  padding: 0.625rem;
  border-radius: 5px;
  color: $light;
  background-color: $brand-300;
  transform: translateY(-50%);

  &.show {
    visibility: visible;
  }

  &.showArrow {
    &::before {
      position: absolute;
      top: 50%;
      left: -7px; /* painfull thing 😪 */
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-top-width: 8px;
      border-right: 8px solid $brand-300;
      border-bottom-width: 8px;
      content: '';
      transform: translateY(-50%);
    }
  }

  &.error {
    background-color: #fff;
    color: $validation-error;
    border: 1px solid $validation-error-light;
  }
}
