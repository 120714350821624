@import '~styles/index';

.link {
  text-decoration: underline;
  color: $brand-300;

  &:hover {
    color: $brand-400;
  }

  &:focus,
  &:active {
    color: $brand-500;
  }
}
