@import '~styles/index';

.menu {
  z-index: $z-index-select-menu;
  min-width: 100%;
}

.wrapper,
.reference {
  position: relative;
  display: inline-block;
}
