@import '~styles/index';

.leftButton {
  max-width: 100%;

  @include text-ellipsis();
  @include border-right-radius(0);
}

.rightButton {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 0;

  @include border-left-radius(0);
}

.dropdownWrapper {
  margin: (-$panel-padding-x) (-$panel-padding-y);
}

.chevron {
  font-size: 10px;
}
