@import '~styles/index';

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: $border;
  border-radius: 50%;

  @include square(2.2em);
}
