@import '~styles/index';

.formInputBlock {
  margin-bottom: 1.5rem;
}

.label {
  display: block;
  margin-bottom: 0.75rem;
  color: $text-color-body;
  font-size: $font-size-xs;
}

.input {
  font-size: $font-size-md;
}

.hasError {
  border-color: $validation-error !important;
}

.errorMessage {
  display: block;
  margin: 0.565rem 0;
  font-size: $font-size-md;
  color: $validation-error;

  &::first-letter {
    text-transform: uppercase;
  }
}
