@import '~styles/index';

.input {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0.1em;
  border: 1px solid transparent;
  border-radius: $border-radius-sm;
  background: transparent;
  background-color: $brand-100;
  appearance: none;
}
