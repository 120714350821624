@import '~styles/index';

$input-height: 4.6em;

.labeledInput {
  margin: 0 0 0.5em;
}

.input {
  height: $input-height;
}

.mockInput {
  @include input;

  display: flex;
  height: auto;
  min-height: $input-height;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  flex-wrap: wrap;
  cursor: text;

  &.disabled {
    cursor: unset;

    .taggedLabel {
      background-color: $tag-disabled-bg;
      cursor: default;
    }
  }

  &.error {
    border-color: red;
  }
}

.taggedLabel {
  margin: 0.5rem 0.5rem 0 0;
  padding: 0.5em;
  border-radius: 3px;
  color: $brand-primary;
  background-color: $tag-bg;
  display: inline-block;

  &.taggedLabelError {
    color: $validation-error;
    background-color: $red-translucent;
  }
}

.capitalizeTag {
  text-transform: lowercase !important;

  &::first-letter {
    text-transform: capitalize !important;
  }
}
