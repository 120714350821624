@import '~styles/index';

.barItem {
  display: block;

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    left: calc(50% + 10.5px);
    width: 1px;
    height: 50%;
    background-color: $border-color;
  }

  .row:first-child &.hideEdgeBorders::before,
  .row:last-child &.hideEdgeBorders::after {
    display: none;
  }
}

.barItemCaption {
  position: absolute;
  left: 0;
}

.dot {
  position: relative;
  z-index: 8;
}
