@import '~styles/index';

$top-spacing: 4em;

.authPage {
  display: flex;
  height: 100vh;
  min-height: $main-min-height;
  justify-content: center;

  *:not(input) {
    user-select: none;
  }

  .aside {
    position: relative;
    width: 100%;

    .asideImage {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 60rem;
    padding: $top-spacing 4.8em 0;
    background-color: $light !important;
    overflow: auto;

    @include remove-scrollbar();

    .logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .logoImage {
        width: 140px;
        height: 140px;
      }
    }

    .content {
      width: 100%;
      z-index: $z-index-2; // set content layout over sidebar image
    }
  }

  .dialog {
    max-width: $modal-window-width;
    margin: 20% auto;
  }
}
