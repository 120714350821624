@import '~styles/index';

.user {
  width: $auth-form-width;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .userData {
    display: flex;
    align-items: center;

    .avatar {
      width: 5rem !important;
      height: 5rem !important;
      margin: 0 !important;
      font-size: $font-size-xl;
    }

    strong {
      color: $gray-700;
    }

    .info {
      margin-left: 1em;
    }
  }

  .userLink {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.showPasswordIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}

@include screen-min-max($breakpoint-phone, $breakpoint-desktop) {
  .loginForm {
    width: 100%;
  }
}
