@import '~styles/index';

.textarea {
  @include input;

  padding: 1rem;
  resize: none;

  &:not(:global(.input-string)) {
    min-height: $input-height;
  }
}

.textareaWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
}

// for whoever needs prepend on textarea
// .prepend {
// }

.append {
  position: absolute;
  right: $border-width;
  top: $border-width;
  padding: (($input-height - $button-height) - 2) / 2;
  border-bottom-right-radius: $border-radius;
  min-height: $input-height;
  display: flex;
  align-items: center;
}

.languageBadge {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(calc(100% + 1em));
}
