@import '~styles/index';

.menuWrapper {
  position: absolute;
  top: 100%;
  z-index: $z-index-select-menu;
  min-width: 100%;
}

.menu {
  @include border;

  opacity: 1 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-width: 100%;
  max-height: $select-menu-height;
  border-color: $border-color-focus;
  color: $text-color-dark;
  background-color: #fff;
  transition: border-color $transition-duration,
    background-color $transition-duration;

  .select-mode &,
  .suggestions-mode & {
    background-color: $gray-100;
  }

  &.placementTop {
    margin-bottom: -1px;
  }

  &.placementBottom {
    margin-top: -1px;
  }
}

.menuError {
  border-color: $validation-error;
  color: $validation-error;
  background-color: $validation-error-bg;
}

.menuHidden {
  display: none;
}

.option {
  padding: $select-option-padding-y $select-option-padding-x;
  color: $text-color-light;
  cursor: default;

  &[disabled] {
    color: $text-color-lighter;
  }

  .search-mode & {
    padding: $select-option-padding-y $search-option-padding-x;
  }
}

.optionHighlighted {
  background-color: $border-color-hover;
}

.optionSelected {
  color: $text-color-dark;
}

.container {
  position: relative;
  display: block;
}

.selectIcon {
  padding: 0 1rem;
  font-size: $font-size-xxs;
  line-height: 3;
  appearance: none;

  input:focus ~ * & {
    color: $border-color-focus;
  }
}

.input {
  min-width: 0;
  flex-basis: 100%;
}

.clearButton {
  @include size(40px);

  margin: 0;
  padding: 0;
  border: 0;
  color: $gray-400;
  background-color: transparent;
  appearance: none;
}

.innerMenu {
  position: relative;
  height: 100%;
  overflow: auto;
  flex-grow: 1;
}

.appendOption {
  background-color: #fff;
  border-top: 0.5px solid $gray-300;
}
