@import '~styles/index';

$star: '\e931';
$star-stroke: '\e932';

.wrapper {
  display: inline-flex;
}

.star {
  padding: 0.2em;
  color: $brand-200;
}

.active {
  color: $brand-primary;

  &::before {
    content: $star;
  }
}

.editable {
  .star {
    cursor: pointer;

    &:active {
      opacity: 0.7;
    }
  }

  &:hover {
    .star {
      &::before {
        content: $star;
        color: $brand-primary;
      }

      &:hover ~ .star::before {
        content: $star-stroke;
        color: $brand-200;
      }
    }
  }
}
