@import '~styles/index';

.label {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.text {
  font-size: $font-size-sm;
  padding-left: 0.5em;
}

.check {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid $gray-300;
  border-radius: 3px;
  transition: all 0.2s ease;

  svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: $light;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition-delay: 0.1s;
  }

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    background: $brand-300;
    opacity: 1;
    transform: scale(0);
  }

  &:hover {
    border-color: $gray-700;
  }

  .input:checked + & {
    border-color: $brand-300;
    background: $brand-300;
    animation: wave 0.4s ease;

    svg {
      stroke-dashoffset: 0;
    }

    &::before {
      opacity: 0;
      transform: scale(3.5);
      transition: all 0.6s ease;
    }
  }
}

.input {
  display: none;
  visibility: hidden;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
