@import '~styles/index';

@mixin tab-color($icon-color, $text-color) {
  color: $text-color;

  .tabTitle {
    color: $icon-color;
  }
}

.tabWithIcon {
  @include tab-color($text-color-light, $text-color-light);

  white-space: nowrap;
  position: relative;
  margin-right: 20px;
  padding: 0.7em;

  &.tabDone {
    @include tab-color($text-color-dark, $validation-success);
  }

  &.tabActive {
    @include tab-color($text-color-dark, $brand-primary);
  }
}

.tabDone {
  @include tab-color($text-color-dark, $validation-success);
}

.tabDisabled {
  @include tab-color($text-disabled, $text-disabled);

  pointer-events: none;
}

.tabIconDone {
  position: absolute;
  top: -3px;
  right: -5px;
  font-size: $font-size-xs;
  line-height: 6px;
  text-shadow: -1px 1px 0 $light;
  border-radius: 50%;
  background: $light;
}

.tabIconWrapper {
  position: relative;
}

.badge {
  position: absolute;
  top: 0;
  right: -3px;
  width: 16px;
  height: 16px;
  font-size: $font-size-xs;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  color: $white;
  background-color: $validation-error;
}
