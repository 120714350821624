@import '~styles/index';

.statsContainer {
  display: inline-flex;
  width: 100%;
  max-width: 35em;
  margin: 0.5em 0 3em;
  padding: 0.75em 1em 0.5em;
  border: 1px solid $gray-200;
  border-radius: $border-radius-sm;
  background-color: $white;
  align-items: center;

  &:nth-child(n + 1) {
    margin-right: 2em;
  }

  &:last-child {
    margin-right: 0;
  }
}

.statsTextBlock {
  width: 100%;
}

.largeLabel {
  $large: 20px;

  margin-bottom: 5px;
  font-size: $large;
}

.subLabel {
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  white-space: nowrap;
  color: $gray-600;
}

$trend-inc-color: greenyellow;
$trend-dec-color: red;

.trend {
  padding: 11px;
  font-size: $font-size-html;

  &.trendInc {
    color: $trend-inc-color;
  }

  &.trendDec {
    color: $trend-dec-color;
  }
}

.icon {
  width: 3em;
  height: 3em;
  margin-left: 0.75em;
}

@include screen-min-max($breakpoint-phone, $breakpoint-desktop-wide) {
  .subLabel {
    white-space: normal;
  }
}
