@import '~styles/index';

.container {
  position: relative;
  width: 620px;
}

.icon {
  margin-left: 12px;
}

.itemNotFound {
  position: relative;
  z-index: 1;
  margin: 0 (-$search-option-padding-x);
  padding: 0 $search-option-padding-x;

  &:hover {
    background-color: $gray-100;
  }
}

.button {
  height: $button-height;
  padding-top: 11px;
  padding-bottom: 11px;
}
