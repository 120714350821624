@import '~styles/index';

.buttons {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  gap: 1rem;

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    height: 90px;
    padding: 0.8rem;
    border-radius: 4px;
    border: none;
    outline: none;

    &.active {
      background-color: $brand-500;
    }

    @include button-variant-primary();
  }
}
