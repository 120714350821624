@import '~styles/index';

.subMenuItem {
  padding: 0.9em 0.9em;
  color: $brand-primary !important;
  background-color: $gray-300;
  cursor: pointer;
  min-height: 40px;

  .sameCatLink {
    border: 0;
    color: $brand-primary;
    background-color: transparent;
    cursor: pointer;
    font-weight: inherit;
  }

  &:hover,
  &:focus {
    background-color: $gray-400;
  }

  &.subMenuDefault {
    background-color: $brand-primary;

    .sameCatLink {
      color: $light;
    }

    &:hover,
    :focus {
      background-color: $brand-primary--hover;
    }
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
