@import '~styles/index';

.badge {
  display: inline-block;

  // the zero line height in couple with the vertical padding
  // needed for the uppercase text to be centered vertically
  line-height: 0;
  padding: 0.7em 0.3em;
  border-radius: $border-radius-sm;
  font-weight: $font-weight-bold;
  border: 2px solid currentColor;
  color: $brand-primary;
  text-transform: uppercase;
}
