@import '~styles/index';

.wrapper {
  position: relative;
}

.hasError {
  border-color: $validation-error !important;
}

.error {
  top: calc(100% + 0.2em);
  left: 0;
  min-width: 100%;
  background-color: white;
  color: $validation-error;
  border: 1px solid $validation-error-light;
  visibility: visible;
  transform: none;

  &::before {
    display: none;
  }
}
