@import '~styles/index';

.badge {
  min-height: 53px;
  display: flex;
  align-items: center;
  position: fixed;
  top: $main-padding;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-index-loader-badge;
  visibility: hidden;
  min-width: 200px;
  padding: 1em;
  border-radius: $border-radius;
  background-color: $light;
  opacity: 0;
  transition: opacity $transition-duration, visibility $transition-duration;
  box-shadow: $shadow-100;
}

.badgeShow {
  visibility: visible;
  opacity: 1;
}
