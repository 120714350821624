@import '~styles/index';

.container {
  border: 2px dashed $gray-500;
  border-radius: $border-radius;
  background-color: $white;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;

  * {
    pointer-events: none;
  }

  &.dragActive {
    border-color: $brand-300;
    background-color: $brand-150;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropFileImg {
  flex-grow: 1;
}
