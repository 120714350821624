@import '~styles/index';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  list-style-type: none;

  .sidebarPopover {
    opacity: 0;
    transition: opacity $transition-duration, visibility $transition-duration;
  }

  &:hover {
    .sidebarPopover {
      visibility: visible;
      opacity: 1;
    }
  }
}

.icon {
  width: 2.625em;
  height: 2.625em;
  position: relative;
  display: flex;
  transition: background-color $transition-duration;
  align-items: center;
  justify-content: center;

  i {
    color: $gray-400;
    transition: color $transition-duration;
  }
}

.activeLink {
  border-radius: 5px;
  color: $gray-700;
  background-color: $brand-150;

  i {
    color: $brand-300;
  }
}
