@import '~styles/index';

.input {
  margin: 0;
  padding: 0 0.5em;
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 2px;
  color: inherit;
  background-color: transparent;
  appearance: none;
  text-overflow: ellipsis;

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $outline;
  }
}

.error {
  background-color: $red-translucent;

  &:focus-visible {
    box-shadow: $outline-error;
  }
}
