@import '~styles/index';

.itemVideo {
  width: 100%;
  height: auto;
}

.galleryPagingContainer {
  display: flex !important;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  list-style-type: none;
  border-radius: $border-radius;
  background-color: $gray-100;
  align-items: center;
  justify-content: center;

  li {
    height: 100%;
    margin-right: 1em;
  }

  li:last-of-type {
    margin-right: 0;
  }

  .galleryPaging {
    width: 100px;
    height: 100%;
    border-radius: $border-radius;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    object-fit: cover;

    &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
    }
  }

  .mockFile {
    object-fit: contain;
  }
}

.arrows {
  position: absolute;
  top: 36%;
  z-index: 1;
  padding: 0.5em;

  &.nextButton {
    right: 0;
  }
}

.notSupportedContainer {
  margin-top: 11em;
}

.itemContainer {
  display: flex !important;
  padding-right: 3rem;
  padding-left: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.itemImage {
  object-fit: contain;
  width: 100%;
  height: 60vh;

  @include ie11 {
    width: auto;
  }
}
