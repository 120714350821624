@import '~styles/index';

.icon {
  visibility: hidden;
  padding: 0 0.5em;
  font-size: $font-size-xxs;
  color: $text-color-light;
  transition: opacity $transition-duration, visibility $transition-duration;
}

.editable {
  display: inline-flex;
  padding: 0.1em;
  border-radius: $border-radius-sm;
  transition: background-color $transition-duration;
  align-items: center;
  cursor: pointer;

  &.block {
    display: flex;

    .text {
      flex-grow: 1;
    }
  }

  &.show,
  &:hover {
    background-color: $brand-100;

    .icon {
      visibility: visible;
    }
  }
}
