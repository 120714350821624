@import '~styles/index';

.timePicker,
.datePicker {
  @include border;

  min-width: 7.9em;

  &.error {
    border-color: $validation-error;
  }
}

:global {
  .Calendar__Wrapper-sc-3j1sll-5 {
    @include border;

    padding: 0.75em 0 0 0;
  }

  .datePicker__placeholder,
  .timePicker__placeholder {
    color: $gray-400 !important;

    &:hover {
      border: 0 !important;
    }
  }

  .datePicker__value-container,
  .timePicker__value-container {
    line-height: 2.6em;
  }

  .datePicker__control,
  .timePicker__control {
    border: 0 !important;

    &:hover {
      @include border;

      border-color: $brand-300;
    }
  }

  .datePicker__control--is-focused {
    padding: 0;
  }

  .datePicker--is-disabled,
  .timePicker--is-disabled {
    background-color: $gray-100;
  }

  .datePicker__single-value--is-disabled,
  .timePicker__single-value--is-disabled {
    color: $text-color-body !important;
  }
}
