@import '~styles/index';

.imgPlaceholder {
  padding: 0.25rem;
  border-radius: 0 5px 5px 0;
}

.imgPlaceholderContainer,
.imgUpload {
  width: 100%;
  height: 100%;
}

.img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 3px 0 3px $gray-300;
  object-fit: cover;
}

.imgRemoveBtn {
  display: flex;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  font-size: $font-size-smallest;
  outline: none;
  border: 0;
  border-radius: 5px;
  color: $gray-600;
  background: $brand-150;
  align-items: center;
  justify-content: center;
  appearance: none;
  cursor: pointer;

  &:active {
    background-color: $brand-200;
  }
}

.imgContainer {
  position: relative;

  .imgRemoveBtn {
    position: absolute;
    top: -5px;
    right: -5px;
    opacity: 0;
  }

  &:hover {
    .imgRemoveBtn {
      opacity: 1;
    }
  }
}
