@import '~styles/index';

.popperDialog {
  position: relative;
  z-index: $z-index-modal;
}

.popperDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-modal-overlay;
  width: 100%;
  height: 100%;
  background: $gray-shadow;
}
