@import '~styles/index';

.errorMessage {
  display: block;
  margin: 0.565rem 0;
  font-size: $font-size-md;
  color: $validation-error;

  &::first-letter {
    text-transform: uppercase;
  }
}
